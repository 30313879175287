// src/components/PropertyMap.js

import MapGL, { Marker, NavigationControl } from "@urbica/react-map-gl";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useRef } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import TubeDistance from "../components/tube-distance";

import { Button } from "react-bootstrap";
import CartContext from "../context/CartContext";
import TeamMemberCard from "./TeamMemberCard";

const slugify = require("slugify");

const PropertyMap = ({ property, teamMember }) => {
	const {
		subwayStation1,
		subwayStation1DistanceKm,
		subwayStation1DurationMins,
		subwayStation1Lines,
		subwayStation2,
		subwayStation2DistanceKm,
		subwayStation2DurationMins,
		subwayStation2Lines,
		subwayStation3,
		subwayStation3DistanceKm,
		subwayStation3DurationMins,
		subwayStation3Lines,
		airtableId,
		locationLatitude,
		locationLongitude,
		name: propertyName,
		services,
		virtualTourLink,
		amenities,
		type,
		desksFrom,
		rentPM,
		floorsAvailable,
		desksTo,
		features,
		description,
		addressLineOne,
		addressLineTwo,
		city,
		postCode,
		live,
		photos,
		videoId,
		fixedId,
	} = property;

	const {
		cart,
		viewport,
		numberOfPeople,
		OnAddToCart,
		OnRemoveFromCart,
		budgetPerDesk,
		onLocationChangeScroll,
		onLocationChangePan,
		shortOffices,
	} = React.useContext(CartContext);

	const mapRef = useRef();

	const createSlug = (value) => {
		// Ensure the value is a string or provide a default empty string if it's not
		const stringValue = value ? String(value) : "";
		return slugify(stringValue);
	};

	return (
		<Container className="py-5 py-lg-7">
			<Row className="pb-lg-5 align-items-center">
				<Col>
					<TeamMemberCard teamMember={teamMember} />
					<h2 className=" fs-1 mb-3">Seen something that takes your fancy?</h2>
					<p>
						With our market know-how and personalised touch, we’ll make the
						whole process a piece of cake. Book your consultation today!
					</p>
					<div className="d-none d-md-inline-block w-100 w-md-auto">
						<Button
							href="#form-section"
							className={`w-100 justify-content-center w-md-auto fw-bold px-4  d-flex align-items-center`}
							variant="secondary"
						>
							<StaticImage
								src={"../images/dark-bubble.svg"}
								alt="Your Office People"
								height={15}
								layout="fixed"
								className="me-2"
							/>{" "}
							Chat to Us
						</Button>
					</div>
				</Col>
				{createSlug(city).toLowerCase() === "london" && (
					<Col className="text-lg-end" lg={6}>
						<div className="p-0 m-0 d-lg-flex justify-content-lg-end pt-3 ps-2">
							<div className="d-block">
								{subwayStation1 !== null && (
									<TubeDistance
										station={subwayStation1}
										distanceKm={subwayStation1DistanceKm}
										walkTime={subwayStation1DurationMins}
										subwayLines={subwayStation1Lines}
										tube
									/>
								)}
								{subwayStation2 !== null && (
									<TubeDistance
										station={subwayStation2}
										distanceKm={subwayStation2DistanceKm}
										walkTime={subwayStation2DurationMins}
										subwayLines={subwayStation2Lines}
										tube
									/>
								)}
								{subwayStation3 !== null && (
									<TubeDistance
										station={subwayStation3}
										distanceKm={subwayStation3DistanceKm}
										walkTime={subwayStation3DurationMins}
										subwayLines={subwayStation3Lines}
										tube
									/>
								)}
							</div>
						</div>
						<div className="d-md-none w-100 w-md-auto">
							<Button
								href="#form-section"
								className={`w-100 justify-content-center w-md-auto fw-bold px-4  d-flex align-items-center`}
								variant="secondary"
							>
								<StaticImage
									src={"../images/dark-bubble.svg"}
									alt="Your Office People"
									height={17}
									layout="fixed"
									className="me-2"
								/>{" "}
								Chat to Us
							</Button>
						</div>
					</Col>
				)}
			</Row>
			<Row>
				<Col xs={12}>
					<div
						style={{ borderRadius: "16px", overflow: "hidden" }}
						className="mt-4"
					>
						<MapGL
							ref={mapRef}
							style={{ width: "100%", height: "70vh" }}
							mapStyle="mapbox://styles/rjmdigital/clgnfpzan00dl01pcebkc629e"
							accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbTMzNTBiemQxY3hwMmlzYXR5dGRmY3V3In0.LmvASVHMVHLrhorJsOBd0A"
							bounds={viewport?.bounds}
							latitude={viewport?.latitude}
							longitude={viewport?.longitude}
							zoom={viewport?.zoom}
							onViewportChange={(e) => onLocationChangePan(e)}
							viewportChangeMethod="flyTo"
						>
							<NavigationControl
								showZoom
								showCompass={false}
								position="top-right"
							/>
							return (
							<Marker
								latitude={locationLatitude}
								longitude={locationLongitude}

								// onClick={() => onPinClick(property)}
							>
								<StaticImage
									quality="100"
									src={`../images/hover pin.svg`}
									alt="Marker"
									placeholder="blurred"
									style={{
										maxWidth: "48px",
										cursor: "pointer",
									}}
									formats={["auto", "webp"]}
									className="align-middle d-inline-block"
								/>
							</Marker>
							);
						</MapGL>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default PropertyMap;
